import Vue from "vue";
import Vuetify from "vuetify";

import App from "./App.vue";
import router from "./router";
import store from "./store";
import colors from "vuetify/lib/util/colors";
import VCalendar from "v-calendar";
import AsyncComputed from "vue-async-computed";
import moment from "moment";

import "@mdi/font/css/materialdesignicons.css";

import "vuetify/dist/vuetify.min.css";
import "v-calendar/lib/v-calendar.min.css";
import "./assets/scss/main.scss";

// Fix JSON stringify issue for moment
moment.fn.toJSON = function() {
  return this.format();
};

// Use Vuetify
const opts = {
  icons: {
    iconfont: "mdi",
  },
  theme: {
    dark: true,
    themes: {
      dark: {
        accent: colors.red.darken1,
      },
    },
  },
};
Vue.use(Vuetify);

// Use v-calendar, v-date-picker & v-popover components
Vue.use(VCalendar, {
  firstDayOfWeek: 2, // Monday
});

Vue.use(AsyncComputed);

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  filters: {
    capitalise: (str) => str[0].toUpperCase() + str.substring(1),
  },
  vuetify: new Vuetify(opts),
  render: (h) => h(App),
}).$mount("#app");
