import Vue from "vue";
import Router from "vue-router";
import store from "./store";

Vue.use(Router);

const router = new Router({
  mode: "hash",

  routes: [
    {
      path: "/",
      component: () => import("./layouts/LoginLayout"),
      children: [{ path: "", component: () => import("./views/Login") }],
    },
    {
      path: "/dashboard",
      component: () => import("./layouts/DefaultLayout"),
      props: { route: "dashboard" },
      children: [{ path: "", component: () => import("./views/Dashboard") }],
    },
    {
      path: "/mode-calendar",
      component: () => import("./layouts/DefaultLayout"),
      props: { route: "mode-calendar" },
      children: [{ path: "", component: () => import("./views/ModeCalendar") }],
    },
    {
      path: "/profile-calendar",
      component: () => import("./layouts/DefaultLayout"),
      props: { route: "profile-calendar" },
      children: [
        { path: "", component: () => import("./views/ProfileCalendar") },
      ],
    },
    {
      path: "/settings",
      component: () => import("./layouts/DefaultLayout"),
      props: { next: "/" },
      children: [{ path: "", component: () => import("./views/Dashboard") }],
    },
    {
      path: "/settings/ccu-management",
      component: () => import("./layouts/DefaultLayout"),
      props: { route: "ccu-management" },
      children: [
        { path: "", component: () => import("./views/settings/CcuManagement") },
      ],
    },
    {
      path: "/settings/data-map-management",
      component: () => import("./layouts/DefaultLayout"),
      props: { route: "data-map-management" },
      children: [
        {
          path: "",
          component: () => import("./views/settings/DataMapManagement"),
        },
      ],
    },
    {
      path: "/settings/room-management",
      component: () => import("./layouts/DefaultLayout"),
      props: { route: "room-management" },
      children: [
        {
          path: "",
          component: () => import("./views/settings/RoomManagement"),
        },
      ],
    },
    {
      path: "/settings/virtual-switch-management",
      component: () => import("./layouts/DefaultLayout"),
      props: { route: "virtual-switch" },
      children: [
        {
          path: "",
          component: () => import("./views/settings/VirtualSwitchManagement"),
        },
      ],
    },
    {
      path: "/settings/profile-management",
      component: () => import("./layouts/DefaultLayout"),
      props: { route: "profile-management" },
      children: [
        {
          path: "",
          component: () => import("./views/settings/ProfileManagement"),
        },
      ],
    },
    {
      path: "/settings/mode-management",
      component: () => import("./layouts/DefaultLayout"),
      props: { route: "mode-management" },
      children: [
        {
          path: "",
          component: () => import("./views/settings/ModeManagement"),
        },
      ],
    },
    {
      path: "/settings/user-management",
      component: () => import("./layouts/DefaultLayout"),
      props: { route: "user-management" },
      children: [
        {
          path: "",
          component: () => import("./views/settings/UserManagement"),
        },
      ],
    },
    {
      path: "/lab",
      component: () => import("./views/Laboratory"),
    },
  ],
});

export default router;
