import Cookies from 'js-cookie'
import axios from 'axios'

axios.interceptors.request.use(
  config => {
    let authorization = Cookies.get('authorization')
    if (authorization) {
      config.headers['Authorization'] = authorization
    }

    return config
  },
  err => Promise.reject(err)
)

axios.interceptors.response.use(
  res => {
    return res
  },
  err => {
    if (err.response.status === 401) {
      window.location = '/'
    }
    throw err
  })

const api = axios

export default api